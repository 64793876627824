<template>
  <div class="jk-bg-wrapper">
    <div class="jk-wrapper" style="position: relative">

      <div class="jk-header _img">
        <div class="_back" @click="$router.go(-1)"><img src="../status/img/icon_back.png" /></div>
        <div class="_img"><img src="../status/img/my_list_bg.png" /></div>
      </div>

      <div class="my-list"  >
        <div class="_item" :class="{'_current': item.status === 2}" v-for="item of myList" @click="onClickViewItem(item)">
          <van-row>
            <van-col span="18">{{ item.subgoods.title }}</van-col>
            <van-col span="6" style="text-align: right">
              <span v-if="item.status === 1">处理中</span>
              <span v-if="item.status === 4">已完成</span>
              <span v-if="item.status === 5">未成功</span>
            </van-col>
          </van-row>
        </div>
      </div>
      <img src="../status/img/my_list_bg2.png" class="_my_list_bg">
    </div>

    <!-- 确定预约 -->
    <div class="dialog-wrapper" v-if="showSureDialog">
      <div class="_content">
        <div class="_window">
          <div class="_title _img"><img src="../status/img/my_list_d_title.png" /></div>
          <div class="_list">
            <div class="_xiangmu">
              <div class="_h1">预约项目</div>
              <div class="_h2">{{currItem.subgoods.title}}</div>
            </div>
            <div class="_dijian">
              <van-row>
                <van-col span="8">消耗积点</van-col>
                <van-col span="16"><span class="_hd">{{currItem.subgoods.price}}积点</span></van-col>
              </van-row>
            </div>
            <div class="_dijian" v-if="currItem.bookings_date">
              <van-row>
                <van-col span="8">预约时间</van-col>
                <van-col span="16"><span class="_hd">{{currItem.bookings_date}}</span></van-col>
              </van-row>
            </div>
            <div class="_dijian" v-if="currItem.status==4 && currItem.pay_card_no!=''">
              <van-row v-if="currItem.pay_card_no.substr(0,4)=='http'">
                <van-col span="24">卡密:<span class="_hd" @click="show_card_info(currItem.pay_card_no)" >点击查看</span></van-col>
              </van-row>
              <van-row v-else>
                <van-col span="24">卡号:<span class="_hd">{{currItem.pay_card_no}}</span></van-col>
              </van-row>
            </div>
            <div class="_dijian" v-if="currItem.status==4 && currItem.pay_card_pwd!=''">
              <van-row>
                <van-col span="24">密码:<span class="_hd">{{currItem.pay_card_pwd}}</span></van-col>
              </van-row>
            </div>
            <div class="_dijian" v-if="currItem.status==4 && currItem.coupons">
              <van-row>
                <van-col span="3"></van-col>
                <van-col span="21"> <div id="qrCode" ref="qrCodeDiv"></div></van-col>
              </van-row>
              <van-row style="margin-top: 0.2rem" >
                <van-col span="24"><span class="_hd">{{currItem.coupons.code}}</span></van-col>
              </van-row>
              <van-row style="margin-top: 0.2rem" v-if="currItem.coupons.exp_time!=''" >
                <van-col span="24"><span class="_exp_time">有效期:{{ currItem.coupons.exp_time }}</span></van-col>
              </van-row>
            </div>
            <div class="_dijian" v-if="currItem.status==5 && currItem.pay_desc!=''">
              <van-row>
                <van-col span="8">错误</van-col>
                <van-col span="16"><span class="_hd">{{currItem.pay_desc}}</span></van-col>
              </van-row>
            </div>
          </div>
        </div>
        <div class="_closed" @click="showSureDialog = false"><img src="../status/img/icon_closed.png" /></div>
      </div>
    </div>
    <Footer :step="3" />
  </div>
</template>

<script>
import Footer from './components/footer'
import QRCode from 'qrcodejs2';
export default {
  mixins: [],
  components: {Footer},
  data() {
    return {
      showSureDialog: false,
      myList: [],
      currItem:{}
    };
  },

  created() {
  },

  mounted() {
    this.getOrderList();
  },

  methods: {
    onClickViewItem(item) {
      this.currItem=item;
      this.showSureDialog = true;
      console.info(item);
      console.info( item.coupons.qr_info);
      let qrcode = item.coupons.qr_info!=""?item.coupons.qr_info:item.coupons.code;
      console.info(qrcode);
      console.info(qrcode);
      this.$nextTick(() => {
        new QRCode(this.$refs.qrCodeDiv, {
          text: qrcode,
          width: 180,
          height: 180,
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
        });
      });

    },

    // 确认预约
    onClickSureSignup() {
      // this.showSureDialog = false;
      // this.showOkDialog = true;
    },
    show_card_info(url){
      window.open(url);
    },
    async getOrderList() {
      // 获取分类列表
      let {data,code} = await this.$ajax.gateway("/api/api_get_user_order_list", {
        "METHOD": "get"
      });
      this.myList = data.records;
    }
  }
};
</script>

<style lang="less" scoped>
@import "../status/css/jk.less";

.my-list {
  background: #8B1619;
  background-image: linear-gradient(#8B1519, #8B1619);
  box-shadow: 2px 0 4px rgba(0, 0, 0, .15);
  margin: -1.4rem 0.7rem 0;
  min-height: 60vh;
  padding-top: 0.4rem;
  padding-bottom: 2rem;
  border-radius: 0.7rem 0.7rem 0 0;
  position: relative;
  z-index: 1;
  ._item {
    background: #BA1D21;
    background-image: linear-gradient(to right bottom, #BA1D21, #6F1114);
    margin: 0.5rem;
    color: #C8B488;
    line-height: 0.6rem;
    padding: 0.3rem 0.3rem;
    border: 1px solid #C28468;
    border-radius: 0.1rem;
    &._current {
      background: #CFA781;
      color: #851518;
      border-color: #CFA781;
    }
  }
}
._my_list_bg {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 4vh;
  z-index: 2;
}
.dialog-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.45);
  z-index: 100;
  ._content {
    width: 100%;
    position: absolute;
    top: 2rem;
    left: 0;
    ._window {
      margin: 0 0.7rem;
      padding: 0.8rem 0.8rem 1rem;
      background: url("../status/img/dialog_bg.png") repeat-x left center;
      background-size: auto 100%;
      border: 3px solid #CCAD8A;
      border-radius: 0.2rem;
      min-height: 20vh;
      max-height: 70vh;
      overflow-y: scroll;
      img {
        max-width: 100%;
      }

      ._title {
        margin-bottom: 1rem;
      }
      ._status {
        text-align: center;
        margin-top: -0.8rem;
        img {
          width: 1.8rem;
        }
      }
      ._tips {
        width: 110%;
        margin-left: -5%;
        padding: 0.1rem 0 0.5rem;
        font-size: 0.36rem;
        color: #c8b489;
        text-align: center;
      }
      ._list {
        ._xiangmu {
          color: #c8b489;
          text-align: center;
          border: 2px solid #CCAD8A;
          border-radius: 0.2rem;
          padding-bottom: 0.2rem;
          ._h1 {
            font-size: 0.54rem;
            padding: 0.15rem 0;
          }
          ._h2 {
            font-size: 0.4rem;
            padding: 0.2rem 0;
            background: #CCAD8A;
            border-radius: 0.1rem;
            color: #a71b1e;
            margin: 0 0.2rem;
          }
        }
        ._dijian {
          color: #c8b489;
          text-align: center;
          border: 2px solid #CCAD8A;
          border-radius: 0.2rem;
          padding: 0.2rem;
          line-height: 0.8rem;
          margin-top: 0.3rem;
          font-size: 0.36rem;
          span._hd {
            display: block;
            background: #CCAD8A;
            border-radius: 0.1rem;
            color: #a71b1e;
            font-weight: 600;
            font-size: 0.48rem;
          }
          span._exp_time{
            display: block;
            background: #CCAD8A;
            border-radius: 0.1rem;
            color: #a71b1e;
            font-weight: 600;
            font-size: 0.3rem;
          }
        }
      }
      ._btn {
        text-align: center;
        padding: 0.7rem 0 0;
        img {
          max-width: 110%;
          margin-left: -5%;
        }
      }

      &._window_w {
        padding: 0.2rem;
        ._title {
          padding-top: 0.3rem;
          margin-bottom: 0;
        }
      }
    }
    ._closed {
      padding: 0.4rem 0;
      text-align: center;
      img {
        width: 1.2rem;
      }
    }
  }
}
</style>
